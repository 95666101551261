import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  Layout,
  SEO,
  Wrapper,
  Typography,
  GridCell,
  GridRow,
  GridMainContent as DefaultGridMainContent,
  Footer,
  // Button,
  // HorizontalRule,
} from "../components"
import { breakpoints } from "../styles/breakpoints"

const GridMainContent = styled(DefaultGridMainContent)`
  max-width: 800px;

  &.mdc-layout-grid {
    padding-top: var(--gutter-1);
    padding-bottom: 0;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    &.mdc-layout-grid {
      padding-top: var(--gutter-3);
    }
  }
`

// const PrimaryCallToAction = styled(Button)`
//   margin-top: 5px;
// `

const ContentGridRow = styled(GridRow)`
  margin-top: var(--gutter-3);
`

// const AlignedHorizontalRule = styled(HorizontalRule)`
//   margin-top: -29px;

//   @media (max-width: ${breakpoints.PHONE}) {
//     margin-top: -11px;
//   }
// `

const MessagSentPage = () => {
  return (
    <Layout page="/contact">
      <SEO title="Contact Us" />

      <Wrapper>
        <GridMainContent>
          <GridRow>
            <GridCell span={12}>
              <Typography use="headline2">Message successfully sent</Typography>
            </GridCell>
          </GridRow>
          <ContentGridRow>
            <GridCell span={12}>
              <Typography use="body1">
                We will get back in touch with you soon. Have a great day!
              </Typography>
            </GridCell>
          </ContentGridRow>

          <ContentGridRow>
            <GridCell span={12}>
              <Typography use="body1">
                Go back to our <Link to="/">home page</Link>.
              </Typography>
            </GridCell>
          </ContentGridRow>
        </GridMainContent>
      </Wrapper>

      {/* <Wrapper>
        <GridMainContent>
          <GridRow>
            <GridCell span={12}>
              <AlignedHorizontalRule />
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <Typography use="headline3">Want to know more?</Typography>
            </GridCell>

            <GridCell span={12}>
              <PrimaryCallToAction label="Read our blog" size="large" />
            </GridCell>
          </GridRow>

          <ContentGridRow>
            <GridCell span={12}>
              <Typography use="body1">
                Or go back to our <Link to="/">home page</Link>.
              </Typography>
            </GridCell>
          </ContentGridRow>
        </GridMainContent>
      </Wrapper> */}

      <Footer />
    </Layout>
  )
}

export default MessagSentPage
